import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { DynamicImageComponent } from "@customer-formula/shared/components/dynamic-image/dynamic-image.component";
import { CustomerAgentComponent } from "./customer-agent.component";
import { CustomerAgentRoutingModule } from "./customer-agent.routing.module";

@NgModule({
  declarations: [CustomerAgentComponent],
  imports: [CustomerAgentRoutingModule, MatIconModule, DynamicImageComponent],
})
export class CustomerAgentModule {}
