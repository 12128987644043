import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-dynamic-image",
  templateUrl: "./dynamic-image.component.html",
  standalone: true,
})
export class DynamicImageComponent implements OnInit {
  @Input() filename = "";

  extension = "jpg";

  constructor() {}

  ngOnInit(): void {
    if (this.supportsWebp()) {
      this.extension = "webp";
    }
  }

  /**
   * Determine if users browser support webp or not
   *
   * @returns boolean
   */
  supportsWebp(): boolean {
    var elem = document.createElement("canvas");

    if (!!(elem.getContext && elem.getContext("2d"))) {
      // was able or not to get WebP representation
      return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
    } else {
      // very old browser like IE 8, canvas not supported
      return false;
    }
  }
}
